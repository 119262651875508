import pic01 from "../imgs/notReady/1.png";
import pic02 from "../imgs/notReady/2.png";
import pic03 from "../imgs/notReady/3.png";
import { tapkey } from "../components/firebase/FirebaseOnetap";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  return (
    <>
      <div className="Home-works-photos">
        <div
          onClick={() => {
            navigate("/CourseReact");
          }}
        >
          <img
            src={pic01}
            alt="2024 React 課程"
            className="Home-Works-photo-1x1 photo-light-1"
          />
        </div>
        <div
          onClick={() => {
            navigate("/");
          }}
        >
          <img
            src={pic02}
            alt="not ready"
            className="Home-Works-photo-1x1 photo-light-2"
          />
        </div>
        <img
          src={pic03}
          alt="not ready"
          className="Home-Works-photo-1x1 photo-light-3"
        />
        <img
          src={pic03}
          alt="not ready"
          className="Home-Works-photo-1x1 photo-light-3"
        />
        <img
          src={pic03}
          alt="not ready"
          className="Home-Works-photo-1x1 photo-light-3"
        />
        <img
          src={pic03}
          alt="not ready"
          className="Home-Works-photo-1x1 photo-light-3"
        />
      </div>

      <div>
        {/* one tap */}
        <div
          id="g_id_onload"
          data-client_id={tapkey}
          data-context="signin"
          data-login_uri="http://localhost:3000/"
          data-close_on_tap_outside="false"
          data-itp_support="true"
        ></div>
      </div>
    </>
  );
}
