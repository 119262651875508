import React from "react";
import CarouselCourse from "../components/CarouselCourse";
import { useNavigate } from "react-router-dom";

export default function CourseReact() {
  const navigate = useNavigate();

  return (
    <>
      <section>
        <div>
          <CarouselCourse />
        </div>
      </section>
      <section className="course-part">
        <div className="course-part-column">
          <div className="part-title">安裝篇</div>
          <div className="part-p">手把手的安裝視訊</div>
          <div className="part-p">下載連結</div>
          <div className="part-p">vscode第三方套件</div>
          <div className="part-p">npm 第三方套件</div>
          <div className="part-p">github設定</div>
        </div>
        <div className="course-part-column">
          <div className="part-title">核心篇</div>
          <div className="part-p">超精簡 Javascript 基礎</div>
          <div className="part-p">常用 CSS 有哪些</div>
          <div className="part-p">常用 Html 有哪些</div>
          <div className="part-p">網站有哪些頁面？</div>
          <div className="part-p">一起去看看別人的設計</div>
        </div>
        <div className="course-part-column">
          <div className="part-title">順帶內容</div>
          <div className="part-p">Google 登入</div>
          <div className="part-p">RWD 響應式設計</div>
          <div className="part-p">Boostrap 4 </div>
          <div className="part-p">Canva</div>
          <div className="part-p">icon 8</div>
        </div>
      </section>
      <section className="course-part">
        <div className="course-part-column">
          <div className="part-title">實戰頁面</div>
          <div className="part-p">首頁</div>
          <div className="part-p">商品列表</div>
          <div className="part-p">商品介紹</div>
          <div className="part-p">聯絡我們</div>
          <div className="part-p">隱私權條款</div>
        </div>
        <div className="course-part-column">
          <div className="part-title">資料庫篇</div>
          <div className="part-p">Firebase NoSQL 安裝</div>
          <div className="part-p">讀取資料</div>
          <div className="part-p">更新資料</div>
          <div className="part-p">刪除資料</div>
          <div className="part-p">新增資料</div>
        </div>

        <div className="course-part-column">
          <div className="part-title">還有更多待發布...</div>
          <div className="part-p">動態網站上線流程</div>
          <div className="part-p">資安概念</div>
          <div className="part-p">資安實際操作</div>
        </div>
      </section>
      <section className="works-collection ">
        <div className="course-price">
          <div className="course-price-1">！年尾特惠！ </div>
          <div className="course-price-3">1300元</div>
          <div className="course-price-2">990元</div>
          <div className="course-p09">限2025年02月26日完成付款</div>
        </div>
      </section>
      <section className="works-collection ">
        <a
          href="https://buy.stripe.com/9AQ2b6g0JgHjbfy8ww"
          type="button"
          class="btn btn-outline-danger  btn-lg"
          onClick={() => {
            navigate("/CourseReactTopay");
          }}
        >
          年尾轉職？來去上課！
        </a>
      </section>
    </>
  );
}
