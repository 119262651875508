import React from "react";
import "./Privacy.css";

export default function Terms() {
  return (
    <div className="Privacy">
      <p className="Privacy-title">使用條款</p>
      <p className="Privacy-p">感謝您的造訪</p>
      <p className="Privacy-p">
        machlolophus.com 以及旗下所有服務／ 商品 自稱「本公司」、「我們」
      </p>
      <p className="Privacy-p">本網站由本公司擁有並經營</p>

      <p className="Privacy-p">
        希望的每位訪客（“您”）都能獲得安全、愉快的訪問，因此我們制定了以下條款和條件，以便我們雙方都知道對彼此的期望。使用本公司即表示您同意受以下條款的約束。請仔細閱讀。您對本公司的使用表示您接受這些條款和條件。您於本公司內的購買行為也表明您接受這些條款和條件。
      </p>
      <p className="Privacy-title">非排他性權利</p>
      <p className="Privacy-p">
        就本條款與條件而言，非排他性權利是指您根據任何適用法律可能擁有的、經雙方同意不得排除、限製或修改的任何權利。
      </p>
      <p className="Privacy-title">安全</p>
      <p className="Privacy-p">
        根據非排他性權利，machlolophus.com
        不保證本網站（或任何連結網站）上可供下載的任何資料或程式沒有病毒或其他間諜軟體，並且您同意承擔對您的電腦造成任何損壞的風險由於使用本網站而造成的後果，除非是由
        第三方服務商的疏忽造成或促成的。
      </p>
      <p className="Privacy-title">第三方內容</p>
      <p className="Privacy-p">
        本網站/app包含來自第三方經營的網站的資訊或超連結。我們提不對任何此類資訊或網站的內容負責，也不認可任何此類資訊或網站的內容。
      </p>
      <p className="Privacy-title">修訂</p>
      <p className="Privacy-p">
        本公司可以隨時自行決定透過發布修訂後的條款來修訂這些使用條款或以下購買條款，並且這些修訂將在發佈到網站/app或從網站/app上刪除後立即生效。此後您繼續使用本網站/app即表示您接受本網站/app的任何修訂條款、這些使用條款或購買條款。如果您不同意任何修改，則必須立即停止使用網站/app。如果發現任何條款無法執行，您同意使用條款和購買條款的其餘部分仍然完全有效。
      </p>
      <p className="Privacy-title">版權和商標聲明</p>
      <p className="Privacy-p">
        除非另有說明，否則本公司上出現的所有資料，包括文字、網站設計、標誌、圖形、圖示和圖像、照片、圖形、字體和其他資料（統稱為「內容」）及其選擇、組裝和安排受適用的版權法約束。版權所有
        ©
        2024，保留所有權利。所有音訊和視訊剪輯均為本公司或其各自內容提供者的專有財產。本網站所使用的所有軟體均為本公司或軟體供應商的專有財產。您承認本公司包含受版權、商標或其他智慧財產權保護的內容、音訊和視訊剪輯以及軟體，且這些權利是有效的，並且可能以現有或正在開發的所有形式、媒體和技術受到保護。未經本公司事先書面許可，不得以任何形式或任何方式複印、複製、修改、重新發布、上傳、張貼、傳播或散佈本公司的任何資料。保留本文未明確授予的所有權利。任何未經授權使用本公司資料的行為都可能違反版權、商標和其他適用法律，並可能導致刑事或民事處罰。
      </p>
      <p className="Privacy-title">使用網站的免責聲明</p>
      <p className="Privacy-p">
        您明確同意自行承擔使用本網站/app的風險。在任何情況下，machlolophus.com及其董事、管理人員、員工和代表、第三方內容提供者或類似機構均不會保證本網站/app不會中斷或沒有錯誤。
      </p>
      <p className="Privacy-p">
        在任何情況下，我們或參與創建、製作或分發本網站/app的任何其他方均不對因利潤損失、商譽損失、使用損失、數據損失或其他無形損失而造成的任何直接、間接、附帶、特殊或後果性損害承擔責任（即使我們已被告知由於(i)
        使用或無法使用本網站/app，(ii)
        因購買任何資料、資訊或服務而產生的替代商品和服務的採購成本，可能會造成此類損害）透過本網站/app或從本網站/app取得或收到的訊息或進行的交易；
        (iii) 未經授權存取或更改您的註冊資訊、傳輸或資料； (iv)
        任何第三方在本網站/app上的聲明或行為；
        (v) machlolophus.com及其董事、管理人員、員工和代表的疏忽或故意行為，或
        (vi) 與本網站相關的任何其他事項。
      </p>
      <p className="Privacy-p">
        您同意賠償machlolophus.com以及我們的董事、管理人員、員工和代表，使其免受任何您或任何第三方因您的內容而提出的任何索賠或要求，包括合理的法律費用。網站提交、張貼或傳輸、您對本網站/app的使用、您與本本網站/app的連結、您違反使用條款或您侵犯他人的任何權利。
      </p>
      <p className="Privacy-title">您的帳戶義務</p>
      <p className="Privacy-p">考慮到您對本網站/app的使用，您同意：</p>
      <p className="Privacy-p">
        1
        根據註冊表的提示提供真實、準確、最新且完整的個人資訊（「註冊資訊」），並且
      </p>
      <p className="Privacy-p">
        2 維護並及時更新註冊信息，以保持其真實、準確、最新和完整。
      </p>
      <p className="Privacy-p">
        如果您提供的任何資訊不真實、不準確、不是最新的或不完整，或者我們有合理的理由懷疑此類資訊不真實、不準確、不是最新的或不完整，我們可以暫停或終止您的註冊，並拒絕任何及所有當前和完整的資訊。
        您有責任維護您的密碼和註冊的機密性，並對您的密碼和註冊下發生的所有活動承擔全部責任。您同意立即通知我們任何未經授權使用您的密碼或帳戶或任何其他違反安全的行為。machlolophus.com將此註冊資訊視為私人資訊。這些資訊保存在安全伺服器上，以保護其免受外部各方的侵害。我們僅將這些資訊用於處理您的訂單、統計目的以改善我們的網站/app和為您提供的服務、管理我們的網站/app以及通知您您可能感興趣的產品或特別優惠。
      </p>
      <p className="Privacy-title">支付方式</p>
      <p className="Privacy-p">
        信用卡：我們接受所有主要信用卡，包括 Visa 和 MasterCard。
      </p>
      <p className="Privacy-p">Apple pay</p>
      <p className="Privacy-p">Google pay</p>
      <p className="Privacy-p">以及 Stripe內其他付款方式</p>
      <p className="Privacy-p">
        請務必提供您準確的帳單地址和電話號碼 -
        您的信用卡銀行為您存檔的地址和電話號碼。不正確的資訊可能會導致訂單處理延遲。下訂單後，我們將立即向您的信用卡收取費用。這樣做是為了保護您的信用卡詳細資料的安全，因為我們在任何時候都不會儲存您的信用卡詳細資料（重複訂單的情況除外，在這種情況下，您的信用卡詳細資料將由我們的第三方服務提供者儲存。
        了解更多信息，請參閱“定期交付”標題下的信息）。如果我們無法履行您的訂單或某些商品無法出貨，我們將把差額退還至您的信用卡。這些退款可能需要幾個工作天才能顯示在您的帳單上。
      </p>
      <p className="Privacy-title">你的行為</p>
      <p className="Privacy-p">
        您同意不會出於任何商業目的複製、複製、複製、出售、轉售或利用本網站/app的任何部分、使用本網站/app或造訪本網站/app。您同意除了透過我們提供的存取本網站/app的介面之外，不透過任何方式存取本網站/app。
      </p>
      <p className="Privacy-title">我們的權利 </p>
      <p className="Privacy-p">
        machlolophus.com可選擇以電子方式監控本網站的區域，並可能揭露任何內容、記錄或任何類型的電子通訊
        (i) 以滿足任何法律、法規或政府要求； (ii)
        此類揭露對於網站運作是否必要或適當； (iii)
        保護我們的權利或財產或使用者、我們的董事、員工、代表、贊助商、第三方提供者或授權人的權利。我們不負責篩選、監管、編輯或監控此類內容。
      </p>
      <p className="Privacy-p">文字錯誤</p>
      <p className="Privacy-p">
        如果由於文字錯誤而導致產品以不正確的價格列出，machlolophus.com有權拒絕或取消以不正確的價格列出的商品的任何訂單。無論訂單是否已確認且您的信用卡是否已扣款，machlolophus.com
        均有權拒絕或取消任何此類訂單。如果您的信用卡已被收取購買費用並且您的訂單被取消，machlolophus.com
        將立即向您的信用卡帳戶退回錯誤價格金額。
      </p>
      <p className="Privacy-title">終止</p>
      <p className="Privacy-p">
        即使本協議被任何一方終止，這些條款仍然有效。根據適用法律，我們保留在通知或不通知的情況下自行決定暫停或拒絕您存取本網站/app全部或任何部分的權利。您同意終止您對本網站/app的存取可能會在不事先通知的情況下生效，並承認並同意我們可以立即停用或刪除您的註冊以及您註冊下的所有相關資訊和文件，並禁止進一步存取此類文件或地點。此外，您同意我們不會因終止您對本網站/app的造訪而對您或任何第三方承擔任何責任。
      </p>
      <p className="Privacy-title"></p>
      <p className="Privacy-p"></p>
      <p className="Privacy-p"></p>
      <p className="Privacy-title"></p>
      <p className="Privacy-p"></p>
      <p className="Privacy-p"></p>
      <p className="Privacy-title"></p>
      <p className="Privacy-p"></p>
      <p className="Privacy-p"></p>
      <p className="Privacy-p"></p>
    </div>
  );
}
