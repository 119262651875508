import React, { useEffect, useState } from "react";
import axios from "axios";

export default function NewsSubmit() {
  const [data, setdata] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setcontent] = useState("");
  const [author, setauthor] = useState("");

  useEffect(() => {
    axios.get("http://localhost:3307/getNews").then((res) => {
      console.log(res.data);
      setdata(res.data);
    });
    /* ------------------ */
  }, []);

  const btnNewsSubmit = () => {
    axios
      .post("http://localhost:3307/addNews", {
        title: title,
        content: content,
        key: "khiopi",
      })
      .then((res) => {
        console.log(res.data);
      });
  };

  return (
    <>
      <div className="flex-centre ">
        <form className="form-width">
          <div className="row mb-3">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
              Title
            </label>
            <div className="col-sm-10">
              <input
                type="email"
                className="form-control"
                id="inputEmail3"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>
          {/*  */}

          <div className="mb-3">
            <label
              htmlFor="exampleFormControlTextarea1"
              className="flex-centre contact-message-p"
            ></label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="10"
              placeholder="請填入內容"
              onChange={(e) => {
                setcontent(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="flex-centre">
            <div className="btnsubmit-contact" onClick={btnNewsSubmit}>
              送出
            </div>
          </div>
        </form>
      </div>
      <div>
        {data.map((item, index) => {
          return (
            <div key={index}>
              <div>{item.title}</div>
              <pre>{item.content}</pre>
              <div>{item.editor}</div>
            </div>
          );
        })}
      </div>
    </>
  );
}
