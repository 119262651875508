import React, { useState } from "react";
import "./CarouselCourse.scss";
import slide1 from "../imgs/ReactCourse/1.png";
import slide2 from "../imgs/ReactCourse/2.png";
import slide3 from "../imgs/ReactCourse/3.png";
import slide4 from "../imgs/ReactCourse/4.png";
import slide5 from "../imgs/ReactCourse/5.png";
import slide6 from "../imgs/ReactCourse/6.png";

export default function CarouselCourse() {
  return (
    <>
      <div className="flex-centre">
        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="0"
              aria-current="true"
              aria-label="Slide 1"
              className="active btnStyleIndicater"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              className="btnStyleIndicater"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="2"
              aria-label="Slide 3"
              className="btnStyleIndicater"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="3"
              aria-label="Slide 4"
              className="btnStyleIndicater"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="4"
              aria-label="Slide 5"
              className="btnStyleIndicater"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="5"
              aria-label="Slide 6"
              className="btnStyleIndicater"
            ></button>
          </div>
          {/* 圖片區 */}
          <div className="carousel-inner" data-pause="false" /* hover / false*/>
            <div className="carousel-item active">
              <img
                className="d-block w-100"
                src={slide1}
                alt="Second slide"
                data-bs-interval="1000" /*    1000 = 1s */
              />
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src={slide2}
                alt="Second slide"
                data-bs-interval="2000"
              />
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src={slide3}
                alt="Second slide"
                data-bs-interval="2000"
              />
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src={slide4}
                alt="Second slide"
                data-bs-interval="2000"
              />
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src={slide5}
                alt="Second slide"
                data-bs-interval="2000"
              />
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src={slide6}
                alt="Second slide"
                data-bs-interval="2000"
              />
            </div>
          </div>
          {/* btn 前一張 */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          {/* btn 下一張 */}
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}
