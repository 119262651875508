import { useState, useEffect } from "react";
/* import "./App.css"; */
import "./App.scss";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./screens/Home";
import Footer from "./components/footer/Footer";
import Privacy from "./components/footer/Privacy";
import Contact from "./screens/Contact";
import Nav from "./components/header/Nav";
import Terms from "./components/footer/Terms";
import CourseReact from "./screens/CourseReact";
import About from "./screens/About";
import Copyright from "./screens/Copyright";
import NewsSubmit from "./screens/NewsSubmit";
import Signin from "./components/header/Signin";

function App() {
  /* 響應式網頁輔助工具 */
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <div style={{}}>
          <Nav />
        </div>

        <div className="body">
          <div className="body-width">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Terms-and-conditions" element={<Terms />} />
              <Route path="/Privacy" element={<Privacy />} />
              <Route path="/Copyright" element={<Copyright />} />
              <Route path="/About" element={<About />} />
              <Route path="/CourseReact" element={<CourseReact />} />
              <Route path="/Signin" element={<Signin />} />

              {/* 使用者登入 */}
              <Route path="/Contact" element={<Contact />} />

              {/* 管理者登入 */}
              <Route path="/NewsSubmit" element={<NewsSubmit />} />
            </Routes>
          </div>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
