import React from "react";
import start from "../imgs/icon/Start-48.png";
import run from "../imgs/icon/Run-48.png";
import fly from "../imgs/icon/Fly-48.png";
import { isVisible } from "@testing-library/user-event/dist/utils";

export default function About() {
  return (
    <>
      <div className="flex-centre ">
        <div>
          <div className="about-section">
            <div className="about-section-title">創立初衷</div>
            <div className="about-section-p">
              看到國外有許多很棒的網站，台灣也可以試著做出來讓使用者有更多選擇，最初的起點是希望能夠讓生活更便利，不知不覺在發想的過程中體會到了許多樂趣，於是便有了自己動手做的想法。
            </div>
            <div className="about-section-p">
              有鑒於自身的經歷，希望能夠幫助學員擁有自己的小事業，從0到1是最困難的，我們不一定最後在同一個領域持續學習，因此很開心在這裡遇見你，希望我們都依然能夠堅毅邁進。
            </div>
            <div className="about-section-p">
              React 課程便是為了合作、良性競爭亦或者共贏而誕生。
            </div>
            <div className="about-section-p">
              有了同學、夥伴、愛拌嘴的對手，
            </div>
            <div className="about-section-p">望 與你同行</div>
          </div>
        </div>
      </div>
      <div className="flex-centre ">
        <div className="divided-section">
          <div className="about-section">
            <div className="about-section-title">未來發展</div>{" "}
            <div className="about-section-p">
              <img src={start} className="about-future-img-icon " /> 以 React
              為起點，建立各式服務網頁
            </div>
            <div className="about-section-p">
              <img src={run} className="about-future-img-icon" /> 開發
              Android／iOS 雙平台遊戲
            </div>
            <div className="about-section-p">
              <img src={fly} className="about-future-img-icon" />
              商品多語言化
            </div>
          </div>
        </div>
      </div>
      <div className="flex-centre ">
        <div className="divided-section">
          <div className="about-section">
            <div className="about-section-title">現正開發ing</div>
            <div className="about-section-p">
              目前正在企劃的遊戲以 ios / android
              平台為主，希望之後能夠更改部分遊戲內容之後發布到 Steam 平台
            </div>
            <div className="about-section-p">
              目前團隊僅有一人，因為還沒有盈利，無法給予正常的月領薪酬，如果依然有興趣加入開發....
            </div>
          </div>
        </div>
      </div>
      <div className="flex-centre " /* style={{ display: "none" }} */>
        <div className="divided-section">
          <div className="about-section">
            <div className="about-section-title">加入我們</div>{" "}
            <div className="about-section-p">
              UI/UX
              設計＆視覺設計：遊戲介面、角色設計（ＡＩ生成）、遊戲資產設計（ＡＩ生成）
            </div>
            <div className="about-section-p">
              工程師： MySQL, 資安, Unity開發
            </div>
            <div className="about-section-p">
              請透過「聯繫我們」傳送自我介紹／學經歷／技能／作品連結（請勿縮網址）
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
