import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoWords from "../../imgs/logo/logo-NB-words.png";
import "./Nav.scss";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { user } from "../firebase/FirebaseUser";

export default function Nav() {
  const navigate = useNavigate();
  const [userstatus, setuserstatus] = useState(false);
  function btnHome() {
    navigate("/");
  }

  useEffect(() => {
    /* ---------------- */
    onAuthStateChanged(user, (authstatus) => {
      if (authstatus) {
        setuserstatus(true);
      } else {
        setuserstatus(false);
      }
    });
    /* ---------------- */
  }, []);

  const btnSignout = () => {
    signOut(user)
      .then(() => {
        btnHome();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <>
      <div className="header-sticky">
        <div className="header-iteself ">
          <div className="">
            <div>
              <Link to="/">
                <img src={logoWords} className="header-logo-words" />
              </Link>
            </div>
          </div>
          <div className="header-nav">
            <div>
              <Link to="/" className="header-nav-link">
                首頁
              </Link>
            </div>
            <div>
              <Link to="/" className="header-nav-link">
                {/*    Android/iOS遊戲 */}
              </Link>
            </div>

            <div>
              <Link to="/About" className="header-nav-link">
                關於我們
                {/* 接案：網站上線（開發）、網站規劃（諮詢）*/}
              </Link>
            </div>
            {userstatus ? (
              <>
                <div>
                  <a onClick={btnSignout} className="header-nav-link">
                    登出
                  </a>
                </div>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
