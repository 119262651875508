import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCZAg5gWiKgZazDMQmkKU4nEGgqpoka0OY",
  authDomain: "machlolophus-tw.firebaseapp.com",
  projectId: "machlolophus-tw",
  storageBucket: "machlolophus-tw.appspot.com",
  messagingSenderId: "552618121820",
  appId: "1:552618121820:web:67bc3c556824a09387e499",
  measurementId: "G-DN5F0GJ2H5",
};
export const app = initializeApp(firebaseConfig);
