import React from "react";

export default function Copyright() {
  return (
    <>
      {" "}
      <div className="flex-centre ">
        <div>
          <div className="about-section">
            <div className="about-section-title">本站版權聲明</div>
            <div className="copyright-p">
              本站除了「第三方版權聲明」外的其餘圖案, 商標, 網站名稱,
              文字等的版權均為 machlolophus.com 所有。
            </div>
            <div className="about-section-title ">第三方版權聲明</div>

            <a
              href="https://github.com/twbs/bootstrap/blob/v5.2.3/LICENSE"
              target="_blank"
              className="copyright-p  copyright-link"
            >
              Boostrap 5.2.3 採 MIT 版權，歸 Twitter, Inc. ＆ The Bootstrap
              Authors所有，詳情請點此見原網站
            </a>

            <a
              href="https://intercom.help/icons8-7fb7577e8170/en/articles/5136841-are-your-icons-free-for-commercial-use"
              target="_blank"
              className="copyright-p  copyright-link"
            >
              Icon8 根據其規定已於網站尾端標註聲明
            </a>

            <a
              href="https://www.canva.com/policies/content-license-agreement/"
              target="_blank"
              className="copyright-p  copyright-link"
            >
              Canva 根據其規定而使用，詳情請見原網站
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
