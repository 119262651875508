import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { user } from "../components/firebase/FirebaseUser";

export default function Contact() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [group, setgroup] = useState("");
  const [message, setmessage] = useState("請於下方填入內容");
  const [content, setcontent] = useState("");
  const [userstatus, setuserstatus] = useState(false);

  useEffect(() => {
    /* ---------------- */
    onAuthStateChanged(user, (authstatus) => {
      if (authstatus) {
        setuserstatus(true);
      } else {
        setuserstatus(false);
      }
    });
    /* ---------------- */
  }, []);

  return (
    <>
      <div className="flex-centre ">
        <div className="Contact-section-title">聯繫我們</div>
      </div>
      {userstatus ? (
        <>
          <div className="flex-centre ">
            <form className="form-width">
              <div className="row mb-3">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label"
                >
                  Email
                </label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail3"
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label"
                >
                  稱呼
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail3"
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                </div>
              </div>

              <fieldset
                className=" mb-3 flex-centre"
                onChange={(e) => {
                  setgroup(e.target.value);
                  switch (e.target.value) {
                    case "cooperation":
                      setmessage("請簡單直接說明內容／日期／報價和其他資訊");
                      break;
                    case "seeker":
                      setmessage(
                        "請自我介紹／學經歷／技能／作品連結（請勿縮網址）"
                      );
                      break;
                    case "player":
                      setmessage("請簡單說明問題與附上會員編號");
                      break;
                    case "student":
                      setmessage(
                        "請附上作品網址與描述，登入的email務必要和課程平台一致"
                      );
                      break;
                    default:
                      setmessage("請填入內容");
                      break;
                  }
                }}
              >
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="cooperation"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    商業夥伴
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="seeker"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    求職者
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value="player"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    玩家
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio4"
                    value="student"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    學員
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio5"
                    value="user"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    其他
                  </label>
                </div>
              </fieldset>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="flex-centre contact-message-p"
                >
                  {message}
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="12"
                  onChange={(e) => {
                    setcontent(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="flex-centre">
                <div className="btnsubmit-contact">送出</div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="flex-centre ">
            <Link to="/Signin" className="Contact-section-title-2">
              請先登入會員
            </Link>
          </div>
        </>
      )}
      <div className="mini-height">Machlolophus.com</div>
    </>
  );
}
