import React, { useEffect, useState } from "react";
import { tapkey } from "../firebase/FirebaseOnetap";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { user } from "../firebase/FirebaseUser";
import imgGoogle from "../../imgs/icon/Google-48.png";
import { useNavigate } from "react-router-dom";

export default function Signin() {
  const provider = new GoogleAuthProvider();

  const navigate = useNavigate();

  const [msgAlrt, setmsgAlert] = useState("本站僅提供第三方登入");

  useEffect(() => {
    console.log(user.currentUser.uid);
  }, []);
  const btnSignin = () => {
    signInWithPopup(user, provider)
      .then(async (res) => {
        /* note  將使用者資料帶出 */
        navigate(-1);
        /*－－－－－－－－－－－－－－ */
      })
      .catch((error) => {
        console.log(error.message);
        setmsgAlert("帳號或密碼錯誤");
      });
  };
  return (
    <>
      <div>
        <div onClick={btnSignin} className="flex-centre signin-section">
          <div>
            <img src={imgGoogle} className="signin-section-img" />
          </div>
          <div className="signin-section-p">使用 GOOGLE 登入</div>
        </div>
        <div className="msgAlert-p">{msgAlrt}</div>
      </div>
    </>
  );
}
