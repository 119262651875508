import "./Privacy.css";

export default function Privacy() {
  return (
    <>
      <div className="Privacy">
        <p className="Privacy-title">隱私權條款</p>
        <p className="Privacy-p">
          <strong>最後更新時間：</strong>2024 年 2 月 1 日
        </p>
        <p className="Privacy-p ">感謝您造訪 Machlolophus.com 網站</p>
        <p className="Privacy-p">
          我們致力於保護您的個人資訊，我們借與其他第三方合作增強個資安全。本隱私權條款在於幫助您了解我們如何收集、使用和分享您的訊息，以及您如何聯絡我們提出問題、疑慮或要求存取您的資料。
        </p>
        <p className="Privacy-p ">
          我們使用您的個人資料來提供和改進服務。
          透過「使用服務」，您同意按照以下規定收集和使用信息 本隱私權政策。
        </p>
        <p className="Privacy-p ">
          本隱私權政策是在
          <a href="https://www.privacypolicies.com/privacy-policy-發電機/">
            隱私權政策產生器
          </a>
          的幫助下制定的
        </p>
        <p className="Privacy-title">本站與隱私權</p>
        <li className="Privacy-p">
          <strong>帳戶：</strong>
          是指為您存取我們的服務而建立的唯一帳戶或我們服務的一部分。
        </li>
        <li className="Privacy-p">
          <strong>關聯公司：</strong>
          是指控制、受控製或受其控制的實體與一方共同控制，其中「控制」是指擁有
          50%
          的所有權或更多有權投票的股份、股權或其他證券選舉董事或其他管理機構。
        </li>
        {/* todo 設立公司後加上去 */}
        <li className="Privacy-p">
          <strong>
            公司（在本文中稱為「本公司」、「我們」、「我們」或「我們的」）：
          </strong>
          指的是Machlolophus.com
          的自營網站或APP（每個網站/商店/裝置），不包括他人/他公司委託製作或託管。
        </li>
        <li className="Privacy-p">
          <strong>Cookie：</strong>
          是放置在您的電腦、行動裝置或網站的任何其他設備，包含您瀏覽的詳細信息該網站的歷史記錄及其多種用途。
        </li>
        <li className="Privacy-p">
          <strong>設備：</strong>
          是指任何可以存取本服務的設備，例如電腦、手機或數位平板電腦。
        </li>
        <li className="Privacy-p">
          <strong>個人資料：</strong>
          是與已識別或已識別的人相關的任何信息可識別的個人。
        </li>
        <li className="Privacy-p">
          <strong>服務：</strong>指網站、行動應用軟體。
        </li>
        <li className="Privacy-p">
          <strong>服務提供者：</strong>
          是指處理資料的任何自然人或法人代表公司。
          它指的是第三方公司或公司僱用的個人來促進服務、提供代表公司執行與服務相關的服務服務或協助公司分析服務的使用方式。
        </li>
        <li className="Privacy-p">
          <strong>第三方社群媒體服務：</strong>
          指任何網站或任何社群媒體服務使用者可以透過該網站登入或建立帳戶使用該服務。
        </li>
        <li className="Privacy-p">
          <strong>使用數據：</strong>
          是指自動收集的數據，或由使用服務或從服務基礎設施本身（例如，頁面訪問的持續時間）。
        </li>
        <li className="Privacy-p">
          {/* todo 修改為該網站站名 */}
          <strong>網站：</strong>指 Machlolophus，官網
          <a href="https://www.machlolophus.com/">
            https://www.machlolophus.com/
          </a>
        </li>
        <li className="Privacy-p">
          <strong>您：</strong>
          是指存取或使用本服務的個人或公司，或該個人代表其存取或存取的其他法律實體使用服務（如適用）。
        </li>
        <h2 className="Privacy-title ">收集和使用您的個人數據</h2>
        <p className="Privacy-p">
          在使用我們的服務時，我們可能會要求您向我們提供某些個人資訊可用於聯絡或識別您的身分資訊。個人識別資訊可能包括但不限於：
        </p>
        <li className="Privacy-p">電子郵件地址</li>
        <li className="Privacy-p">姓名、性別、生日、年齡</li>
        <li className="Privacy-p">通訊住址</li>
        <li className="Privacy-p">關於您與我們通訊中所含的資訊</li>
        <li className="Privacy-p">關於您在 Machlolophus.com 的交易資訊</li>
        <li className="Privacy-p">關於您在 Machlolophus.com 的活動資訊</li>
        <li className="Privacy-p">使用我們的網站或應用程式時搜尋的資訊</li>
        <li className="Privacy-p">您在社群媒體上與我們的互動</li>
        <li className="Privacy-p">使用數據</li>
        <p className="Privacy-p">
          使用服務時會自動收集使用資料。使用數據可能包括您設備的互聯網協議等信息位址（例如
          IP
          位址）、瀏覽器類型、瀏覽器版本、我們的頁面您造訪的服務、您造訪的時間和日期、花費的時間這些頁面、唯一裝置識別碼和其他診斷資料。
        </p>
        <p className="Privacy-title ">
          當您透過行動裝置存取服務時的額外收集信息
        </p>
        <p className="Privacy-p">
          當您透過行動裝置存取服務時，我們可能會收集自動取得某些信息，包括但不限於：
        </p>
        <li className="Privacy-p">您使用的行動裝置</li>
        <li className="Privacy-p">您的行動裝置唯一 ID</li>
        <li className="Privacy-p">您的 IP 位址</li>
        <li className="Privacy-p">行動裝置的操作系統</li>
        <li className="Privacy-p">行動互聯網類型</li>
        <li className="Privacy-p">您使用的瀏覽器</li>
        <li className="Privacy-p">唯一裝置識別碼和其他診斷資料</li>
        <p className="Privacy-p">
          我們也可能收集您的瀏覽器在您造訪我們的網站時所發送的訊息，或當您透過行動裝置存取服務時。
        </p>
        <p className="Privacy-title ">來自第三方社交媒體服務的訊息</p>
        <p className="Privacy-p">
          本公司允許您建立帳戶並登入以使用服務透過以下第三方社群媒體服務：
        </p>
        <li className="Privacy-p">Google</li>
        <li className="Privacy-p">蘋果ID</li>
        <li className="Privacy-p">Line</li>
        <p className="Privacy-p">
          如果您決定透過第三方註冊或以其他方式授予我們存取第三方的權限，我們可能會收集已經存在的個人數據與您的第三方社交媒體服務的帳戶關聯，例如您的姓名、您的電子郵件地址、您的活動或與以下內容相關的聯絡人列表那個帳戶。
        </p>
        <p className="Privacy-p">
          您即授予公司使用、共享和儲存的權限以符合本隱私權政策的方式。
        </p>
        <p className="Privacy-title ">追蹤技術和 Cookie</p>
        <p className="Privacy-p">
          我們使用 Cookie 和類似的追蹤技術來追蹤我們的活動 服務並儲存某些資訊。
          使用的追蹤技術是信標， 標籤和腳本來收集和追蹤資訊以及改進和分析
          我們的服務。 我們使用的技術可能包括：
        </p>
        <li className="Privacy-p">
          Cookie 或瀏覽器 Cookie。 Cookie 是放置在您的 裝置.
          您可以指示您的瀏覽器拒絕所有 Cookie 或指示 當 Cookie 被發送時。
          但是，如果您不接受 Cookie，則您 可能無法使用我們服務的某些部分。
          除非你已經調整好 您的瀏覽器設定將拒絕我們的服務可能使用的 Cookie
          餅乾。
        </li>
        <li className="Privacy-p">
          網路信標。 我們的服務和電子郵件的某些部分可能包含
          稱為網路信標（也稱為清晰網路信標）的小型電子文件 gif、像素標籤和單像素
          gif），允許公司 例如，統計造訪過這些頁面或開啟電子郵件的使用者數量
          以及其他相關網站統計資料（例如，記錄
          某個版塊的受歡迎程度並驗證系統和伺服器 正直）。
        </li>
        <p className="Privacy-p">
          Cookie 可以是「持久性」Cookie 或「會話」Cookie。 持久 Cookie
          保持開啟狀態 當您離線時，您的個人電腦或行動裝置在會話期間
          一旦您關閉網頁瀏覽器，Cookie 就會被刪除。
        </p>
        <p className="Privacy-p">
          我們為下列目的使用會話 Cookie 和持久 Cookie：
        </p>
        <li className="Privacy-p">必要/必備的 Cookie</li>
        <li className="Privacy-p">類型：會話 Cookie</li>
        <li className="Privacy-p">管理人：我們</li>
        <li className="Privacy-p">
          目的：這些 Cookie 對於向您提供服務至關重要
          透過網站提供，並使您能夠使用其中的一些 特徵。
          它們有助於驗證用戶身份並防止欺詐性使用 用戶帳戶。 如果沒有這些
          Cookie，您所要求的服務 無法提供，我們僅使用這些 Cookie 向您提供
          那些服務。
        </li>
        <li className="Privacy-p">Cookie 政策/通知 接受 Cookie</li>
        <li className="Privacy-p">類型：持久性 Cookie</li>
        <li className="Privacy-p">管理人：我們</li>
        <li className="Privacy-p">
          目的：這些 Cookie 辨識使用者是否已接受 Cookie 的使用 在網站上。
        </li>
        <li className="Privacy-p">功能性 Cookie</li>
        <li className="Privacy-p">類型：持久性 Cookie</li>
        <li className="Privacy-p">管理人：我們</li>
        <li className="Privacy-p">
          目的：這些 Cookie 使我們能夠記住您在使用時所做的選擇
          網站，例如記住您的登入詳細資訊或語言 偏愛。 這些 Cookie 的目的
        </li>
        <p className="Privacy-title ">您的個人資料的使用</p>
        <p className="Privacy-p">公司可能將個人資料用於以下目的：</p>
        <li className="Privacy-p">
          提供和維護我們的服務，包括監控使用情況 我們的服務。
        </li>
        <li className="Privacy-p">
          管理您的帳戶：管理您作為本網站使用者的註冊 服務。
          您提供的個人資料可以讓您存取不同的 您作為註冊用戶可以使用的服務功能
          用戶。
        </li>
        <li className="Privacy-p">
          對於合約的履行：開發、合規和 產品、物品或服務的採購合約的承諾
          您已透過本服務購買了我們的產品或與我們簽訂了任何其他合約。
        </li>
        <li className="Privacy-p">
          與您聯繫：透過電子郵件、電話、簡訊或其他方式與您聯繫
          同等形式的電子通信，例如行動電話 應用程式有關更新或資訊的推播通知
          與功能、產品或合約相關的通信
          服務，包括安全性更新，在必要或合理的情況下 他們的實施。
        </li>
        <li className="Privacy-p">
          為您提供有關新聞、特別優惠和一般信息
          我們提供的其他商品、服務和活動與這些類似
          您已經購買或詢問過的商品，除非您選擇 不接收此類資訊。
        </li>
        <li className="Privacy-p">
          管理您的要求：出席並管理您向我們提出的請求。
        </li>
        <li className="Privacy-p">
          對於業務轉讓：我們可能會使用您的資訊來評估或進行
          合併、剝離、重組、改組、解散或
          我們部分或全部資產的其他出售或轉讓，無論是作為持續資產
          關注或作為破產、清算或類似程序的一部分，
          我們持有的有關我們服務用戶的哪些個人資料屬於資產 轉入。
        </li>
        <li className="Privacy-p">
          用於其他目的：我們可能會將您的資訊用於其他目的，例如
          作為數據分析，確定使用趨勢，確定有效性
          我們的促銷活動以及評估和改進我們的服務， 產品、服務、行銷和您的體驗。
        </li>
        <p className="Privacy-title ">我們可能在以下情況下共享您的個人資訊：</p>
        <li className="Privacy-p">
          與服務提供者：向我們提供與我們業務相關的其他管理貨營運服務的第三方、安全服務提供者、市場研究或回饋收集公司、IT服務提供者、支付處理商和收款人或其他廠商。
        </li>
        <li className="Privacy-p">
          對於業務轉讓：我們可能會分享或轉讓您的個人資訊
          與公司的任何合併、出售有關或在其談判期間
          資產、融資或收購我們全部或部分業務，以 另一家公司。
        </li>
        <li className="Privacy-p">
          與關聯公司：我們可能會與我們的關聯公司分享您的訊息，
          在這種情況下，我們將要求這些附屬公司遵守本隱私權政策。
          關聯公司包括我們的母公司和任何其他子公司、合資公司
          我們控製或受控的風險合作夥伴或其他公司 與我們共同控制。
        </li>
        <li className="Privacy-p">
          與業務合作夥伴：我們可能會與我們的業務分享您的訊息
          合作夥伴向您提供某些產品、服務或促銷活動。
        </li>
        <li className="Privacy-p">
          與專業顧問：在需要時使任何此類顧問能夠向我們提供服務。
        </li>{" "}
        <li className="Privacy-p">
          與執法人員或其他第三方：協助我們調查並防止任何潛在、可疑或實際違法或詐欺活動所需的第三方。
        </li>
        <li className="Privacy-p">
          與其他使用者：當您分享個人資訊或以其他方式時
          在公共區域與其他使用者互動，此類資訊可能是
          可供所有使用者查看，並可在外部公開分發。 如果你
          與其他用戶互動或透過第三方社群媒體註冊
          服務，您在第三方社交媒體服務上的聯絡人可能會看到
          您的姓名、個人資料、照片和您的活動描述。 相似地，
          其他用戶將能夠查看您的活動的描述， 與您溝通並查看您的個人資料。
        </li>
        <li className="Privacy-p">
          經您同意：我們可能會出於任何目的而披露您的個人資訊
          經您同意的其他目的。
        </li>
        <p className="Privacy-title ">行銷、廣告和客戶洞察</p>
        <p className="Privacy-p">
          作為我們業務活動的一部分以及為了推廣我們可以向您提供的產品和服務，我們可能會使用並向第三方披露我們從您或受信任的第三方（包括我們的關聯公司）收集的個人信息，以用於獲取有關您的見解、廣告（包括線上行為廣告和網站/應用程式個人化）以及其他直接行銷和個人化通訊的目的。
        </p>
        <p className="Privacy-p">
          我們可能會聘請第三方協助我們確保您對我們提供的行銷和廣告感興趣。
        </p>
        <p className="Privacy-p">我們有可能會接受廣告置入而披露您的訊息。</p>
        <li className="Privacy-p">
          向您發送有關我們商品或服務的促銷或資訊的電子郵件。
        </li>
        <li className="Privacy-p">
          向您提供有針對性的廣告內容，包括透過第三方網站/應用程式。
        </li>
        <li className="Privacy-p">
          第三方在我們的網站/應用程式推送針對性的廣告內容。
        </li>
        <p className="Privacy-title ">保留您的個人數據</p>
        <p className="Privacy-p">
          公司只會在必要時保留您的個人數據 用於本隱私權政策中規定的目的。
          我們將保留並使用您的 遵守我們的法律義務所需的個人數據
          （例如，如果我們需要保留您的資料以遵守適用的
          法律）、解決爭議並執行我們的法律協議和政策。
        </p>
        <p className="Privacy-p">
          公司也將保留使用數據用於內部分析目的。 用法
          數據通常會保留較短的時間，除非該數據 用於加強安全性或改進我們的功能
          服務，或我們有法律義務更長時間地保留這些數據 期間。
        </p>
        <p className="Privacy-title ">您的個人資料的轉移</p>
        <li className="Privacy-p">
          您的資訊（包括個人資料）由公司處理
          經營辦事處以及參與各方的任何其他地方 加工地點。
          這意味著該資訊可能會被轉移到——
          並在位於您所在州、省、國家以外的電腦上進行維護
          或其他資料保護法可能不同的政府管轄區 比您管轄範圍內的人。
        </li>
        <li className="Privacy-p">
          您同意本隱私權政策，然後提交此類信息 資訊代表您同意該轉讓。
        </li>
        <p className="Privacy-p">
          本公司將採取一切合理必要的措施來確保您的數據
          根據本隱私權政策進行安全處理，並且不會轉讓
          您的個人資料將被保存到某個組織或國家，除非
          有足夠的控制措施，包括您的資料的安全性和 其他個人資訊。
        </p>{" "}
        <p className="Privacy-title ">刪除您的個人數據</p>
        <p className="Privacy-p">
          您有權刪除或要求我們協助刪除 我們收集的有關您的個人資料。
        </p>
        <p className="Privacy-p">
          我們的服務可能使您能夠刪除有關您的某些信息 從服務內部。
        </p>
        <p className="Privacy-p">
          您可以隨時登入更新、修改或刪除您的訊息
          您的帳戶（如果有的話），然後訪問帳戶設定部分 允許您管理您的個人資訊。
          您也可以聯絡我們 請求存取、更正或刪除您擁有的任何個人資訊 提供給我們。
        </p>
        <p className="Privacy-p">
          但請注意，當我們 有這樣做的法律義務或合法依據。
        </p>
        <p className="Privacy-title ">揭露您的個人數據</p>
        <p className="Privacy-p">1.商業交易</p>
        <p className="Privacy-p">
          如果公司涉及合併、收購或資產出售，您的個人資料可能會被轉移。
          我們將在您個人之前發出通知 資料被傳輸並受到不同的隱私權政策的約束。
        </p>
        <p className="Privacy-p">2.執法</p>
        <p className="Privacy-p">
          在某些情況下，公司可能需要披露您的信息
          如果法律要求或回應有效請求，則提供個人數據
          公共當局（例如法院或政府機構）。
        </p>
        <p className="Privacy-title ">其他法律要求</p>
        <p className="Privacy-p">
          公司可能會善意地披露您的個人數據，並相信此類數據 需要採取行動：
        </p>
        <li className="Privacy-p">遵守法律義務</li>
        <li className="Privacy-p">保護和捍衛公司的權利或財產</li>
        <li className="Privacy-p">防止或調查與服務相關的可能的不當行為</li>
        <li className="Privacy-p">保護服務使用者或公眾的人身安全</li>
        <li className="Privacy-p">避免承擔法律責任</li>
        <p className="Privacy-title ">您的個人資料的安全</p>
        <p className="Privacy-p">
          您的個人資料的安全對我們很重要，但請記住，沒有
          透過互聯網傳輸的方法，或電子儲存的方法 100% 安全。
          雖然我們努力使用商業上可接受的方式來保護
          您的個人數據，我們無法保證其絕對安全。
        </p>
        <p className="Privacy-title ">兒童隱私</p>
        <p className="Privacy-p">
          我們的服務不針對 13 歲以下的任何人。我們不會故意 收集 13
          歲以下任何人的個人識別資訊。
          如果您是父母或監護人並且您知道您的孩子已提供
          我們有個人數據，請聯絡我們。 如果我們意識到我們有
          在未經驗證的情況下收集 13 歲以下任何人的個人數據
          經父母同意，我們會採取措施從我們的網站中刪除該訊息 伺服器。
        </p>
        <p className="Privacy-p">
          如果我們需要依賴同意作為處理您的資訊的法律依據
          並且您所在的國家/地區需要父母的同意，我們可能需要您父母的同意
          在我們收集和使用該資訊之前徵得您的同意。
        </p>{" "}
        <p className="Privacy-title ">其他網站的連結</p>
        <p className="Privacy-p">
          我們的服務可能包含非我們經營的其他網站的連結。
          如果您點擊第三方鏈接，您將被定向到該第三方的 地點。
          我們強烈建議您查看您造訪的每個網站的隱私權政策 訪問。
        </p>
        <p className="Privacy-p">
          我們無法控制內容、隱私，也不承擔任何責任
          任何第三方網站或服務的政策或做法。
        </p>
        <p className="Privacy-title ">本隱私權政策的變更</p>
        <p className="Privacy-p">
          我們可能會不時更新我們的隱私權政策。 我們將通知您任何
          透過在此頁面上發布新的隱私權政策進行更改。
        </p>
        <p className="Privacy-p">
          我們將事先透過電子郵件和/或我們服務上的顯著通知告知您
          更改生效並更新頂部的“上次更新”日期 本隱私權政策的。
        </p>
        <p className="Privacy-p">
          建議您定期查看本隱私權政策以了解任何變更。
          本隱私權政策的變更在發佈到此後即生效 頁。
        </p>{" "}
        {/* todo 更新 */}
        <p className="Privacy-title ">第三方服務提供者</p>
        <li className="Privacy-p">Google Analytics</li>
        <li className="Privacy-p">Google Firebase</li>
        <li className="Privacy-p">Google與其相關服務</li>
        <li className="Privacy-p">A2 hosting</li>
        <li className="Privacy-p">Stripe</li>
        {/*       <li className="Privacy-p">Line</li> */}
        {/*        <li className="Privacy-p">YouTube</li> */}
        <li className="Privacy-p">Icons8</li>
        <li className="Privacy-p">Canva</li>
        <li className="Privacy-p">Boostrap</li>
        {/* todo 修改為該網站站名 */}
        <p className="Privacy-p-email">
          如果您對本隱私權政策有任何疑問，您可以聯絡我們，透過 email:
          info@machlolophus.com
        </p>
      </div>
    </>
  );
}
