import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
  return (
    <>
      <div className="footer-itself">
        <div className="footer-section-L">
          <div className="footer-p ">
            ※ ios/android App為免費下載和使用，App內另有付費服務/(虛擬)商品。
          </div>

          <div className="footer-p ">
            ※ 請依個人興趣、能力、進行體驗，請注意遊戲時間，避免沉迷於遊戲。
          </div>

          <div className="flex-left ">
            <div>
              <Link to="/Privacy" className="footer-p footer-link">
                -隱私權條款
              </Link>
            </div>
            <div>
              <Link to="/Terms-and-conditions" className="footer-p footer-link">
                -服務條款
              </Link>
            </div>
            <div>
              <div>
                <Link to="/Contact" className="footer-p footer-link">
                  -聯繫我們
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-section-R">
          <div>
            <Link to="/Copyright" className="footer-p footer-link">
              版權©2024 Machlolophus.com
            </Link>
          </div>
          <div className="footer-p ">
            App／Web icons by{" "}
            <a
              href="https://icons8.com/"
              target="_blank"
              className="footer-link"
            >
              icons8
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
